table {
  margin-top: 30px;
  border-spacing: 0;
  border: 1px solid black;
  text-align: center;
  background-color: #b4cde6;
}

tr:last-child td {
  border-bottom: 0;
}

.noUnderline {
  border-bottom: 0;
}

th,
td {
  margin: 0;
  padding: 0.5rem;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  width: 100px;
  height: 20px;
}

td:last-child {
  border-right: 0;
}

th:last-child {
  border-right: 0;
}
