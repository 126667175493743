.inputs {
  margin-top: 10px;
  background-color: #b4cde6;
}

input {
  opacity: 0.8;
  margin: 0;
  padding: 7px;
  width: 100px;
  height: 20px;
}

.submit {
  display: none;
}
