.navbar {
  background-color: #8eb3da;
  border-bottom: 1px solid black;
  padding-left: 15px;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 10px;
}

.text {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  opacity: 0.6;
}

:hover.text {
  opacity: 1;
}

.selected {
  opacity: 1;
}

.btn {
  margin: auto 5px auto auto;
  border-radius: 4px;
  border: 2px solid black;
  background-color: transparent;
  padding: 5px 10px;
  cursor: pointer;
}

.login {
  margin: auto 5px auto auto;
}

.disappear {
  display: none;
}
