.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}

.mode {
  margin: 5px;
  cursor: pointer;
}

.flipping {
  margin-top: 10px;
  display: flex;
  gap: 100px;
}

.link {
  text-decoration: none;
  color: black;
}
